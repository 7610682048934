require([
        'jquery',
		'local/form/BookingSearch',
        'ps/local-storage'
    ], function(
		$,
		BookingSearchForm,
        LocalStorage
    ) {

    $(document).ready(function() {
		var form = new BookingSearchForm($('.search-form-container #search-form'));

        var storage = new LocalStorage(form.getStorageName());
        form.populateForm(storage.load());
    });
});